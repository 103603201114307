import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import LightboxWrapper from '../../components/lightbox/lightboxwrapper'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const NewOurCollectionPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="02-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{ backgroundImage: 'url(../../img/our-collection.jpg)' }}
        ></div>
        <article className="post">
          <h1>New "Our Collection" Page</h1>
          <p>
            After the collection structure modification beginning of 2021, we
            decided to redesign this page on our website. Our clients will have
            a much more imersive and intuitive way to discover each Longines
            collection highlighting our two main segments “Classic” and “Sport”
            and through the new design with background pictures and a sample of
            the watches they will find behind our collection names.
          </p>

          <LightboxWrapper>
            <a href={'../../img/our-collections-classic1.jpg'}>
              <img
                src={'../../img/our-collections-classic1.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>

          <LightboxWrapper>
            <a href={'../../img/our-collections-classic2.jpg'}>
              <img
                src={'../../img/our-collections-classic2.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>

          <LightboxWrapper>
            <a href={'../../img/our-collections-entry.jpg'}>
              <img
                src={'../../img/our-collections-entry.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>

          <div className="spacer"></div>

          <div className="row align-items-center justify-content-center">
            <div className="col-md-8">
              <div className="alert alert-light text-primary shadow">
                <div className="d-flex align-items-center">
                  <ion-icon
                    name="rocket-outline"
                    style={{ marginRight: '30px', fontSize: '36px' }}
                  ></ion-icon>
                  <div>
                    <h3>Go live ?</h3>
                    <p className="mb-0">Q4 2021</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <blockquote>
            <div className="d-flex flex-column flex-md-row">
              <img
                src={'../../img/steven-valente.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:steven.valente@longines.com">
                    steven.valente@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default NewOurCollectionPage

export const pageQuery = graphql`
  query NewOurCollectionPage_02_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
